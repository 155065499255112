<template>
    <nav class="navbar fixed-top navbar-dark navbar-expand-lg bg-dark shadow">
        <div class="container">     
            <router-link to="/dashboard" class="nav-link navbar-brand" :class="{'active': page_current == 'dashboard'}">
                <img class="align-top" :src="logo" height="30" border="0">
            </router-link>
            <template v-if="platform == 'desktop'">
                <template v-if="pipe == 3">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <router-link to="/dashboard" class="nav-link" :class="{'active': page_current == '' || page_current == 'dashboard'}">
                                <i class="fas fa-home mr-2"></i>Início
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/events" class="nav-link" :class="{'active': page_current == 'events'}">
                                <i class="fas fa-fire mr-2"></i>Eventos
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/bets" class="nav-link" :class="{'active': page_current == 'bets'}">
                                <i class="fas fa-receipt mr-2"></i>Apostas
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/leagues" class="nav-link" :class="{'active': page_current == 'leagues'}">
                                <i class="fas fa-trophy mr-2"></i>Ligas
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle pr-0" href="javascript:;" id="dropdown-menu-odds" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :class="{'active': page_current == 'odds'}">
                                    <i class="fas fa-exchange-alt fa-rotate-90 mr-2"></i>Cotações
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-odds">
                                        <router-link to="/dashboard/configs?nav=live-odds" class="dropdown-item" :class="{'active': isLivePageActive}" :data-ative="isLivePageActive">
                                            <i class="fas fa-signal-stream fa-rotate-90 mr-2"></i>Ao vivo
                                        </router-link>
                                        <router-link to="/dashboard/odds" class="dropdown-item" :class="{'active': page_current == 'odds'}">
                                            <i class="fas fa-exchange-alt fa-rotate-90 mr-2"></i>Pré-jogo
                                        </router-link>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="javascript:;" id="dropdown-menu-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-more">
                                        <div class="pt-2 pl-3 pr-3 pb-3 border-bottom">
                                            <button class="btn btn-success btn-block" @click="fastCredit">
                                                <i class="fa fa-bolt mr-2"></i>Crédito rápido
                                            </button>
                                        </div>
                                        <h6 class="dropdown-header">Usuários</h6>
                                        <router-link to="/dashboard/user" class="dropdown-item" :class="{'active': page_current == 'user'}">
                                            <i class="fas fa-user-plus mr-2"></i>Novo usuário
                                        </router-link>
                                        <router-link to="/dashboard/users" class="dropdown-item" :class="{'active': page_current == 'users'}">
                                            <i class="fas fa-users mr-2"></i>Listar usuários
                                        </router-link>
                                        <h6 class="dropdown-header">Financeiro</h6>
                                        <router-link to="/dashboard/cash/general" class="dropdown-item" :class="{'active': page_current == 'general'}">
                                            <i class="fas fa-coins mr-2"></i>Caixa geral
                                        </router-link>
                                        <router-link to="/dashboard/cash/manager" class="dropdown-item" :class="{'active': page_current == 'manager'}">
                                            <i class="fas fa-coins mr-2"></i>Caixa gerente
                                        </router-link>
                                        <router-link to="/dashboard/cash/clients" class="dropdown-item" :class="{'active': page_current == 'clients'}">
                                            <i class="fas fa-coins mr-2"></i>Caixa clientes
                                        </router-link>
                                        <router-link to="/dashboard/cash/supervisor" class="dropdown-item" :class="{'active': page_current == 'supervisor'}">
                                            <i class="fas fa-coins mr-2"></i>Caixa supervisor
                                        </router-link>
                                        <router-link to="/dashboard/cash/salesman" class="dropdown-item" :class="{'active': page_current == 'salesman'}">
                                            <i class="fas fa-coins mr-2"></i>Caixa vendedor
                                        </router-link>
                                        <router-link to="/dashboard/cash/salesman-by-manager" class="dropdown-item" :class="{'active': page_current == 'salesman-by-manager'}">
                                            <i class="fas fa-coins mr-2"></i>Caixa ven. por gerente
                                        </router-link>
                                        <h6 class="dropdown-header">Controle de odds</h6>
                                        <router-link to="/dashboard/rules-leagues" class="dropdown-item" :class="{'active': page_current == 'rules-leagues'}">
                                            <i class="fad fa-shield mr-2"></i>Regras por liga
                                        </router-link>
                                        <router-link to="/dashboard/rules-clients" class="dropdown-item" :class="{'active': page_current == 'rules-clients'}">
                                            <i class="fas fa-user mr-2"></i>Regras por clientes
                                        </router-link>
                                        <router-link to="/dashboard/rules-managers" class="dropdown-item" :class="{'active': page_current == 'rules-managers'}">
                                            <i class="fas fa-shield-alt mr-2"></i>Regras por gerente
                                        </router-link>
                                        <router-link to="/dashboard/rules-salesman" class="dropdown-item" :class="{'active': page_current == 'rules-salesman'}">
                                            <i class="fas fa-user-shield mr-2"></i>Regras por vendedor
                                        </router-link>
                                        <div class="dropdown-divider"></div>
                                        <router-link to="/dashboard/store" class="dropdown-item" :class="{'active': page_current == 'store'}">
                                            <i class="fas fa-shopping-cart mr-2"></i>Loja
                                        </router-link>
                                        <router-link to="/dashboard/casino" class="dropdown-item" :class="{'active': page_current == 'casino'}">
                                            <i class="fas fa-dice-three mr-2"></i>Cassino
                                        </router-link>
                                        <router-link to="/dashboard/sweepstakes" class="dropdown-item" :class="{'active': page_current == 'sweepstakes'}">
                                            <i class="fas fa-gift mr-2"></i>Sorteios
                                        </router-link>
                                        <router-link to="/dashboard/challenge" class="dropdown-item" :class="{'active': page_current == 'challenge'}">
                                            <i class="fad fa-badge-dollar mr-2"></i>Desafios
                                        </router-link>
                                        <router-link to="/dashboard/results" class="dropdown-item" :class="{'active': page_current == 'results'}">
                                            <i class="fas fa-poll-h mr-2"></i>Resultados
                                        </router-link>
                                        <router-link to="/dashboard/live-events" class="dropdown-item" :class="{'active': page_current == 'live-events'}">
                                            <i class="fas fa-signal-stream mr-2"></i>Eventos ao vivo
                                        </router-link>
                                        <div class="dropdown-divider"></div>
                                        <router-link to="/dashboard/transactions" class="dropdown-item" :class="{'active': page_current == 'transactions'}">
                                            <div class="d-flex align-items-center justify-content-between">
                                                <span>
                                                    <i class="fas fa-wallet mr-2"></i>Transações
                                                </span>
                                                <span class="badge badge-pill badge-warning mt-1" v-if="pedidos_de_saques">{{ pedidos_de_saques }}</span>
                                            </div>
                                        </router-link>
                                        <router-link to="/dashboard/logs" class="dropdown-item" :class="{'active': page_current == 'logs'}">
                                            <i class="fas fa-user-secret mr-2"></i>Logs do sistema
                                        </router-link>
                                        <router-link to="/dashboard/commission-standard" class="dropdown-item" :class="{'active': page_current == 'commission-standard'}">
                                            <i class="fas fa-percent mr-2"></i>Padrão de comissões
                                        </router-link>                                        
                                        <div class="dropdown-divider"></div>
                                        <router-link to="/dashboard/help" class="dropdown-item" :class="{'active': page_current == 'help'}">
                                            <i class="fas fa-life-ring mr-2"></i>Ajuda
                                        </router-link>
                                        <a class="dropdown-item" @click="futurePredictions">
                                            <i class="fas fa-chart-line mr-2"></i>Previsões
                                        </a>
                                        <router-link to="/dashboard/releases" class="dropdown-item" :class="{'active': page_current == 'releases'}">
                                            <i class="fad fa-envelope-open-dollar mr-2"></i>Lançamentos
                                        </router-link>
                                        <router-link to="/dashboard/payment" class="dropdown-item" :class="{'active': page_current == 'payment'}">
                                            <i class="fas fa-usd-circle mr-2"></i>Pagar sistema
                                        </router-link>
                                        <router-link to="/dashboard/configs" class="dropdown-item" :class="{'active': page_current == 'configs'}">
                                            <i class="fas fa-cog mr-2"></i>Configurações
                                        </router-link>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </template>
                <template v-else>
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item">
                            <router-link to="/dashboard" class="nav-link" :class="{'active': page_current == '' || page_current == 'dashboard'}"><i class="fas fa-home mr-2"></i>Início</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/bets" class="nav-link" :class="{'active': page_current == 'bets'}"><i class="fas fa-receipt mr-2"></i>Apostas</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/dashboard/cash/general" class="nav-link" :class="{'active': page_current == 'general'}"><i class="fas fa-coins mr-2"></i>Caixa</router-link>
                        </li>
                        <li class="nav-item">
                            <div class="dropdown">
                                <a class="nav-link dropdown-toggle" href="javascript:;" id="dropdown-menu-more" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-ellipsis-v mr-2"></i>Mais
                                    <div class="dropdown-menu" aria-labelledby="dropdown-menu-more">
                                        <div class="pt-2 pl-3 pr-3 pb-3 border-bottom">
                                            <button class="btn btn-success btn-block" @click="fastCredit">
                                                <i class="fa fa-bolt mr-2"></i> Crédito rápido
                                            </button>
                                        </div>
                                        <h6 class="dropdown-header">Financeiro</h6>
                                        <router-link to="/dashboard/cash/manager" class="dropdown-item" :class="{'active': page_current == 'manager'}" v-if="pipe == 5"><i class="fas fa-coins mr-2"></i>Caixa gerente</router-link>
                                        <router-link to="/dashboard/cash/salesman" class="dropdown-item" :class="{'active': page_current == 'salesman'}"><i class="fas fa-coins mr-2"></i>Caixa vendedor</router-link>
                                        <h6 class="dropdown-header">Usuários</h6>
                                        <router-link to="/dashboard/user" class="dropdown-item" :class="{'active': page_current == 'user'}"><i class="fas fa-user-plus mr-2"></i>Novo usuário</router-link>
                                        <router-link to="/dashboard/users" class="dropdown-item" :class="{'active': page_current == 'users'}"><i class="fas fa-users mr-2"></i>Listar usuários</router-link>
                                    </div>
                                </a>
                            </div>
                        </li>           
                    </ul>
                </template>
                <button class="btn btn-danger mr-3" v-if="pipe == 3" @click="futurePredictions">
                    <i class="fas fa-chart-line"></i>
                </button>
                <div class="dropdown">
                    <button class="btn btn-outline-warning my-2 my-sm-0 dropdown-toggle" id="dropdown-menu-account" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-user-circle mr-2"></i>Conta
                        <div class="dropdown-menu" aria-labelledby="dropdown-menu-account">
                            <h6 class="dropdown-header">
                                <div class="text-truncate" style="max-width: 120px;">Olá, {{user_name}}</div>
                            </h6>
                            <template v-if="pipe == 2 || pipe == 5">
                                <div class="dropdown-divider"></div>
                                <div class="dropdown-header">
                                    <i class="far fa-dollar-sign mr-2"></i>
                                    <div class="float-right">{{current_credit | formatCoin}}</div>
                                </div>
                            </template>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item" href="javascript:;" @click="logout"><i class="fas fa-sign-out-alt mr-2"></i>Sair</a>
                        </div>
                    </button>
                </div>
            </template>
            <template v-else>
                <button class="btn btn-outline-warning my-2 my-sm-0" @click="sidebar = true">
                    <i class="fas fa-bars"></i>
                </button>
                <div class="sidebar" :class="{'sidebar-show': sidebar}">
                    <div class="sidebar-backdrop" @click="sidebar = false"></div>
                    <div class="sidebar-content">
                        <div class="list-group list-group-flush" @click="sidebar = false">
                            <div class="list-group-item list-dark-header bg-dark">
                                <div class="text-center">
                                    <i class="fad fa-user-circle display-4"></i>
                                    <p class="mb-2">Olá, {{user_name}}</p>
                                </div>
                                <div class="w-100 mt-3 mb-3" v-show="pipe == 2">
                                    <div class="row">
                                        <div class="col text-left text-white text-truncate"><i class="far fa-dollar-sign mr-2"></i>Crédito</div>
                                        <div class="col text-right text-white text-truncate">{{current_credit}}</div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-auto pr-0">
                                        <button class="btn btn-danger btn-block" @click="logout">
                                            <i class="fas fa-sign-out-alt"></i>
                                        </button>  
                                    </div>
                                    <div class="col">
                                        <button class="btn btn-success btn-block" @click="fastCredit">
                                            <i class="fa fa-bolt mr-2"></i>Crédito rápido
                                        </button>
                                    </div>
                                </div>                         
                            </div>
                            <div class="list-group-item list-dark-header">
                                <i class="fas fa-hashtag mr-2"></i>Principal
                            </div>
                            <router-link to="/dashboard" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == '' || page_current == 'dashboard'}">
                                <i class="fas fa-home mr-2"></i>Início
                            </router-link>
                            <router-link to="/dashboard/events" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'events'}">
                                <i class="fas fa-fire mr-2"></i>Eventos
                            </router-link>
                            <router-link to="/dashboard/bets" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'bets'}">
                                <i class="fas fa-receipt mr-2"></i>Apostas
                            </router-link>
                            <router-link to="/dashboard/leagues" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'leagues'}">
                                <i class="fas fa-trophy mr-2"></i>Ligas
                            </router-link>
                            <router-link to="/dashboard/store" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'store'}">
                                <i class="fas fa-shopping-cart mr-2"></i>Loja
                            </router-link>
                            <router-link to="/dashboard/help" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'help'}">
                                <i class="fas fa-life-ring mr-2"></i>Ajuda
                            </router-link>
                            <router-link to="/dashboard/live-events" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'live-events'}">
                                <i class="fas fa-signal-stream mr-2"></i>Ao vivo
                            </router-link>
                            <a class="list-group-item list-group-item-action list-dark" v-if="pipe == 3" @click="futurePredictions">
                                <i class="fas fa-chart-line mr-2"></i>Previsões
                            </a>
                            <router-link to="/dashboard/results" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'results'}">
                                <i class="fas fa-poll-h mr-2"></i>Resultados
                            </router-link>
                            <router-link to="/dashboard/transactions" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'transactions'}">
                                <div class="d-flex align-items-center justify-content-between">
                                    <span>
                                        <i class="fas fa-wallet mr-2"></i>Transações
                                    </span>
                                    <span class="badge badge-pill badge-warning mt-1" v-if="pedidos_de_saques">{{ pedidos_de_saques }}</span>
                                </div>
                            </router-link>
                            <router-link to="/dashboard/releases" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'releases'}">
                                <i class="fad fa-envelope-open-dollar mr-2"></i>Lançamentos
                            </router-link>
                            <router-link to="/dashboard/payment" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'payment'}">
                                <i class="fas fa-usd-circle mr-2"></i>Pagar sistema
                            </router-link>
                            <router-link to="/dashboard/configs" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'configs'}">
                                <i class="fas fa-cog mr-2"></i>Configurações
                            </router-link>
                            <router-link to="/dashboard/logs" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'logs'}">
                                <i class="fas fa-user-secret mr-2"></i>Logs do sistema
                            </router-link>
                            <router-link to="/dashboard/commission-standard" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'commission-standard'}">
                                <i class="fas fa-percent mr-2"></i>Padrão de comissões
                            </router-link>
                            <div class="list-group-item list-dark-header">
                                <i class="fas fa-hashtag mr-2"></i>Usuários
                            </div>
                            <router-link to="/dashboard/user" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'user'}">
                                <i class="fas fa-user-plus mr-2"></i>Novo usuário
                            </router-link>
                            <router-link to="/dashboard/users" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'users'}">
                                <i class="fas fa-users mr-2"></i>Listar usuários
                            </router-link>
                            <div class="list-group-item list-dark-header">
                                <i class="fas fa-hashtag mr-2"></i>Financeiro
                            </div>
                            <router-link to="/dashboard/cash/general" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'general'}">
                                <i class="fas fa-coins mr-2"></i>Caixa geral
                            </router-link>
                            <router-link to="/dashboard/cash/manager" v-if="pipe == 3 || pipe == 5" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'manager'}">
                                <i class="fas fa-coins mr-2"></i>Caixa gerente
                            </router-link>
                            <router-link to="/dashboard/cash/clients" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'clients'}">
                                <i class="fas fa-coins mr-2"></i>Caixa clientes
                            </router-link>
                            <router-link to="/dashboard/cash/supervisor" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'supervisor'}">
                                <i class="fas fa-coins mr-2"></i>Caixa supervisor
                            </router-link>
                            <router-link to="/dashboard/cash/salesman" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'salesman'}">
                                <i class="fas fa-coins mr-2"></i>Caixa vendedor
                            </router-link>
                            <router-link to="/dashboard/cash/salesman-by-manager" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'salesman-by-manager'}">
                                <i class="fas fa-coins mr-2"></i>Caixa ven. por gerente
                            </router-link>
                            <div class="list-group-item list-dark-header" v-if="pipe == 3">
                                <i class="fas fa-hashtag mr-2"></i>Controle de odds
                            </div>
                            <router-link to="/dashboard/odds" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'odds'}">
                                <i class="fas fa-exchange-alt fa-rotate-90 mr-2"></i>Cotações
                            </router-link>
                            <router-link to="/dashboard/rules-leagues" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'rules-leagues'}">
                                <i class="fad fa-shield mr-2"></i>Regras por liga
                            </router-link>
                            <router-link to="/dashboard/rules-clients" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'rules-clients'}">
                                <i class="fas fa-user mr-2"></i>Regras por cliente
                            </router-link>
                            <router-link to="/dashboard/rules-managers" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'rules-managers'}">
                                <i class="fas fa-shield-alt mr-2"></i>Regras por gerente
                            </router-link>
                            <router-link to="/dashboard/rules-salesman" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'rules-salesman'}">
                                <i class="fas fa-user-shield mr-2"></i>Regras por vendedor
                            </router-link>
                            <div class="list-group-item list-dark-header" v-if="pipe == 3">
                                <i class="fas fa-hashtag mr-2"></i>Entretenimento
                            </div>
                            <router-link to="/dashboard/casino" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'casino'}">
                                <i class="fas fa-dice-three mr-2"></i>Cassino
                            </router-link>
                            <router-link to="/dashboard/sweepstakes" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'sweepstakes'}">
                                <i class="fas fa-gift mr-2"></i>Sorteios
                            </router-link>
                            <router-link to="/dashboard/challenge" v-if="pipe == 3" class="list-group-item list-group-item-action list-dark" :class="{'active': page_current == 'challenge'}">
                                <i class="fad fa-badge-dollar mr-2"></i>Desafios
                            </router-link>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </nav>
</template>

<script>
import {api} from '../api'
import Swal from 'sweetalert2'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

export default {
    data() {
        return {
            pipe: 0,
            dbUsers: {},
            user_name: '',
            list_users_type: {
                1: 'vendedor',
                2: 'gerente',
                4: 'cliente',
                5: 'supervisor'
            },
            page_current: document.location.pathname.split('/').pop(),
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    methods: {
        logout() {
            Swal.fire({
				title: 'Atenção!',
				html: `<b>${this.user_name}</b> você deseja realmente sair da sua conta?`,
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				cancelButtonText: 'Não',
				confirmButtonText: 'Sim'
			}).then((result) => {

                if (result.value) 
                {
                    this.removeItem('x-token').then(() => {
                        this.removeItem('auth').then(() => {
                            this.removeItem('firebase:pipe').then(() => {
                                window.location.href = '/';
                            });
                        });
                    });
                }
            });
        },
        removeItem(key) {
            return Promise.resolve().then(() => {
                window.localStorage.removeItem(key)
            });
        },
        futurePredictions() {

            const self = this;

            Swal.fire({
                title: 'Previsões futuras',
                html: 'Aguarde... <br /> Esse processo pode demorar um pouco.',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
                showCancelButton: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });

            api.get('dashboard/future-predictions').then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.futurePredictionsHtml(response.data.events);
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            response.data.message,
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Falha',
                        self.network_erros[error.status]['message'],
                        'error'
                    );
                } catch(e) {
                    Swal.fire(
                        'Falha',
                        self.network_erros[408]['message'],
                        'error'
                    );
                }
            });
        },
        futurePredictionsHtml(events) {

            let html = ``;

            for (let key in events) {
                let event = events[key];

                html += '<div class="row">';

                html += `<div class="col pt-2 pb-2 bg-dee2e6 text-truncate">
                    <span>${event.league.name}</span>
                </div>`;

                html += '<div class="w-100"></div>';

                for (const item of event.events) {
                    html += `<div class="col-12">
                        <div class="row border-top pt-3 mb-3">
                            <div class="col text-truncate text-center">
                                <div class="clearfix">
                                    <img src="${item.team_home.image}" border="0" alt="${item.team_home.name}" onerror="this.src='/images/shield_default.png'" width="32" height="32">
                                </div>
                                <span>${item.team_home.name}</span>
                                <div class="w-100">
                                    <small class="text-primary">${item.percents.home}</small>
                                </div>
                            </div>
                            <div class="col-auto text-center align-self-center">
                                <div class="w-100">
                                    <p class="m-0">
                                        <small class="text-danger">${item.date}</small>
                                    </p>
                                    <img class="align-middle" src="/images/versus_symbol.png" alt="X">
                                    <p class="m-0 pt-1">
                                        <small class="text-primary">${item.percents.draw}</small>
                                    </p>
                                </div>
                            </div>
                            <div class="col text-truncate text-center">
                                <div class="clearfix">
                                    <img src="${item.team_away.image}" border="0" alt="${item.team_away.name}" onerror="this.src='/images/shield_default.png'" width="32" height="32">
                                </div>
                                <span>${item.team_away.name}</span>
                                <div class="w-100">
                                    <small class="text-primary">${item.percents.away}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-100"></div>`;
                }

                html += '</div>';             
            }

            Swal.fire(
                'Previsões futuras',
                `<div class="swal-scroll-area">
                    ${html}
                </div>`
            );
        },
        async fastCredit() {

            const { value: formUser } = await Swal.fire({
                title: 'Crédito rápido',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Continuar',
                html: `
                    <div>De quem você quer alterar?</div>
                    <select id="swal-input1" class="swal2-input w-100">
                        ${this.pipe == 3 ? `
                            <option value="1" selected>Vendedor</option>
                            <option value="2">Gerente</option>
                            <option value="4">Cliente</option>
                            <option value="5">Supervisor</option>    
                        ` : this.pipe == 2 ? `
                            <option value="1" selected>Vendedor</option>
                        ` : `
                            <option value="1" selected>Vendedor</option>
                            <option value="2">Gerente</option>
                        `}
                    </select>
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        type: document.getElementById('swal-input1').value
                    }
                }
            });

            if (formUser instanceof Object) 
            {
                this.getListUsers(formUser.type)
            }
        },
        getListUsers(type) {

            const self = this;
        
            Swal.fire({
                title: 'Por favor, aguarde!',
                html: `Estamos carregando a lista de ${self.list_users_type[type]}...`,
                allowOutsideClick: false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                },
            });

            api.get(`dashboard/type/users/${type}`).then((response) => {
                switch(response.data.result) {
                    case 'success':
                        self.mountListUsers(response.data.users, type);
                    break;
                    case 'nothing_found':
                        Swal.fire(
                            'Atenção!',
                            `Não encontramos nenhum ${self.list_users_type[type]} disponível!`,
                            'warning'
                        );
                    break;
                    default:
                        Swal.fire(
                            'Atenção!',
                            'Ocorreu um erro, tente novamente!',
                            'warning'
                        );
                    break;
                }
            }).catch((error) => {
                try {
                    Swal.fire(
                        'Atenção!',
                        self.network_erros[error.status]['message'],
                        'warning'
                    );
                } catch(e) {
                    Swal.fire(
                        'Atenção!',
                        self.network_erros[408]['message'],
                        'warning'
                    );
                }
            });
        },
        mountDBListUsers(users) {
            for (const user of users) {
                this.dbUsers[user._id] = user;
            }
        },
        async mountListUsers(items, type) {

            const self = this;
            const userType = self.list_users_type[type];

            await self.mountDBListUsers(items);

            const { value: formValues } = await Swal.fire({
                title: `Selecionar ${userType}`,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Alterar',
                html: `
                    <div id="swal-virtual-select" data-style="swall"></div>
                    <div id="swal-append"></div>
                `,
                customClass: {
                    actions: 'swal2-actions swal-virtual-select'
                },
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        id: document.getElementById('swal-virtual-select').value,
                        credito: document.querySelector('.swal-input2') ? document.querySelector('.swal-input2').value : 0,
                        credito_simples: document.querySelector('.swal-input3') ? document.querySelector('.swal-input3').value : 0
                    }
                },
                onOpen: () => {

                    const options = [];
                    const users = items.sort((a, b) => {
                        return a.nome.localeCompare(b.nome);
                    });

                    const total_users = users.length;

                    if (total_users) {
                        for (const user of users) {
                            options.push({
                                value: user._id,
                                label: user.nome,
                                description: user.endereco
                            });
                        }
                    }

                    window.VirtualSelect.init({
                        ele: '#swal-virtual-select',
                        search: true,
                        placeholder: `Selecione um ${userType}`,
                        noOptionsText: 'Nenhuma opção disponível',
                        noSearchResultsText: 'Nenhum resultado encontrado',
                        searchPlaceholderText: `Buscar ${userType} (${total_users})...`,
                        hasOptionDescription: true,
                        options: options
                    });

                    if (document.querySelector('#swal-virtual-select')) {
                        document.querySelector('#swal-virtual-select').addEventListener('change', function() {
                            
                            if (this.value) {

                                document.querySelector('#swal-append').innerHTML = `
                                    <div class="row align-items-center">
                                        <div class="col text-left">Crédito</div>
                                        <div class="col">
                                            <input class="form-control swal-input2" type="text" value="${self.dbUsers[this.value].credito}" />    
                                        </div>
                                        <div class="w-100 mb-3" style="${type != 1 ? 'display:none;': ''}"></div>
                                        <div class="col text-left" style="${type != 1 ? 'display:none;': ''}">Crédito simples</div>
                                        <div class="col" style="${type != 1 ? 'display:none;': ''}">
                                            <input class="form-control swal-input3" type="text" value="${self.dbUsers[this.value].credito_simples}" />    
                                        </div>  
                                    </div>    
                                `;

                                if (document.querySelector('.swal-input2')) {
                                    document.querySelector('.swal-input2').addEventListener("input", (e) => {
                                        return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    });
                                }

                                if (document.querySelector('.swal-input3')) {
                                    document.querySelector('.swal-input3').addEventListener("input", (e) => {
                                        return e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    });
                                }
                            } 
                            else 
                            {
                                document.querySelector('#swal-append').innerHTML = '';
                            }
                        });
                    }
                }
            });

            if (formValues instanceof Object) 
            {
                if (formValues.id) {

                    const id = formValues.id;
                    const name = self.dbUsers[id].nome;
                    const credito = formValues.credito || 0;
                    const credito_simples = formValues.credito_simples || 0;

                    Swal.fire({
                        title: 'Alterar crédito',
                        html: `Você deseja mesmo alterar o crédito do ${userType} "<b>${name}</b>" ?`,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Sim'
                    }).then(function(result) {

                        if (result.value) {
                            
                            Swal.fire({
                                title: 'Por favor, aguarde!',
                                html: `Estamos alterando o crédito do ${self.list_users_type[type]}...`,
                                allowOutsideClick: false,
                                onBeforeOpen: () => {
                                    Swal.showLoading()
                                },
                            });

                            api.put(`dashboard/change-credit/${id}/${credito}/${type}/credit-type/3?credit_simple=${credito_simples}`).then((response) => {
                                switch(response.data.result) {
                                    case 'success':
                                        
                                        self.updateCurrentCredit(type, credito, credito_simples);

                                        Swal.fire(
                                            'Parabéns!',
                                            `O crédito do ${userType} "<b>${name}</b>" foi alterado com sucesso!`,
                                            'success'
                                        )
                                    break;
                                    default:
                                        Swal.fire(
                                            'Falha',
                                            response.data.message,
                                            'error'
                                        )
                                    break;
                                }
                            }).catch((error) => {
                                try {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[error.status]['message'],
                                        'error'
                                    );
                                } catch(e) {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[408]['message'],
                                        'error'
                                    );
                                }
                            });
                        }
                    });
                }
                else
                {
                    Swal.fire(
                        'Atenção!',
                        `É obrigatório selecionar um <b>${userType}</b> para poder alterar o crédito!`,
                        'warning'
                    );
                }
            }
        },
        updateCurrentCredit(type, credito, credito_simples) {
            if (this.current_credit && (this.pipe == 2 || this.pipe == 5)) {
                switch(type) {
                    case 2:
                        this.current_credit = parseFloat(this.current_credit) - parseFloat(credito);
                    break;
                    default:
                        this.current_credit = parseFloat(this.current_credit) - parseFloat(credito_simples);
                    break;
                }
            }
        }
    },
    filters: {
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		}
    },
    watch: {
        sidebar(value) {

            let html = document.getElementsByTagName('html')[0];

            if (value) {
                html.className += 'overflow-hidden';
            } else {
                html.classList.remove('overflow-hidden');
            }
        },
    },
    beforeMount() {
        try {
            const {name, pipe} = JSON.parse(window.localStorage.getItem('auth'));
            this.pipe = pipe;
            this.user_name = name;
        } catch(e) {
            this.pipe = 0;
            this.user_name = 'Anônimo';
        }
    },
    created() {
        window.addEventListener('popstate', (e) => {

            e.preventDefault();

            if (this.sidebar) {
                this.sidebar = false;
            }

            history.pushState(null, null, document.location.origin);
        });

        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.dropdown-toggle:after {
  border: none;
}
.card.no-border {
    border: none!important;
}
.sidebar {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1031;
    visibility: hidden;
    transition: visibility 0.3s;
}
.sidebar .sidebar-backdrop {
    width: 100vh;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.21176470588235294);
}
.sidebar .sidebar-content {
    width: 40vh;
    height: 100%;
    top: -1px;
    right: -40vh;
    position: absolute;
    background-color: #3b3b3b;
    transition: right 0.3s;
    -webkit-overflow-scrolling: touch;
}
.sidebar .sidebar-content .list-group {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}
.sidebar.sidebar-show {
    visibility: visible;
}
.sidebar.sidebar-show .sidebar-content {
    right: -1px;
}
.list-group-item.list-dark {
    color: #999;
    background-color: transparent;
}
.list-group-item.list-dark.active {
    color: #fff;
    border-color: rgba(0,0,0,.125);
}
.list-group-item.list-dark-header {
    color: #ffc107;
    border: none;
    background-color: #333;
}
</style>